import React from 'react'
import { HybrisLogoImg, ReactLogoImg, SpringBootLogoImg } from '../../assets'

const Services = () => {
  return (
    <section
      id="services"
      data-w-id="51510f03-ae6a-3f1d-e615-7254736713fc"
      style={{
        WebkitTransform:
          "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        MozTransform:
          "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        msTransform:
          "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        transform:
          "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        opacity: 0
      }}
      className="section-services"
    >
      <h4 className="heading-sub">Services</h4>
      <h1 className="heading-main">Our Technology Stacks</h1>
      <div className="w-layout-grid grid-services">
        <div
          id="w-node-_51510f03-ae6a-3f1d-e615-725473671402-503f3fac"
          data-w-id="51510f03-ae6a-3f1d-e615-725473671402"
          style={{
            WebkitTransform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            MozTransform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            msTransform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            transform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            opacity: 0
          }}
          className="div-feature"
        >
          <div className="div-service">
            <img
              src={ReactLogoImg}
              loading="lazy"
              width={147}
              alt=""
            />
          </div>
          <h3
            id="w-node-_51510f03-ae6a-3f1d-e615-725473671405-503f3fac"
            className="heading-service"
          >
            React
          </h3>
          <p className="paragraph-service">
            Experience lightning-fast browsing with a clean, minimalist design
            that maximizes efficiency.
          </p>
        </div>
        <div
          id="w-node-_6949ba8f-1020-9062-c0c0-41ed7b443503-503f3fac"
          data-w-id="6949ba8f-1020-9062-c0c0-41ed7b443503"
          style={{
            WebkitTransform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            MozTransform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            msTransform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            transform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            opacity: 0
          }}
          className="div-feature"
        >
          <div className="div-service">
            <img
              src={HybrisLogoImg}
              loading="lazy"
              width={218}
              sizes="(max-width: 479px) 100vw, 218px"
              alt=""
              srcSet="images/SAP-Commerce-Cloud---Logo-p-500.png 500w, images/SAP-Commerce-Cloud---Logo.png 600w"
            />
          </div>
          <h3
            id="w-node-_6949ba8f-1020-9062-c0c0-41ed7b443505-503f3fac"
            className="heading-service"
          >
            SAP Commerce Cloud
          </h3>
          <p className="paragraph-service">
            Expert advisory services for optimizing your Hybris Cloud platform.
          </p>
        </div>
        <div
          id="w-node-bb0ccf99-4422-ead6-756f-d4084b526ded-503f3fac"
          data-w-id="bb0ccf99-4422-ead6-756f-d4084b526ded"
          style={{
            WebkitTransform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            MozTransform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            msTransform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            transform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            opacity: 0
          }}
          className="div-feature"
        >
          <div className="div-service">
            <img
              src={SpringBootLogoImg}
              loading="lazy"
              width={267}
              alt=""
            />
          </div>
          <h3
            id="w-node-bb0ccf99-4422-ead6-756f-d4084b526def-503f3fac"
            className="heading-service"
          >
            Spring Boot
          </h3>
          <p className="paragraph-service">
            Versatile Spring Boot applications for scalable and robust backend
            solutions.
          </p>
        </div>
      </div>
    </section>
    
  )
}

export default Services
