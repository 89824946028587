import React from 'react'
import { AboutUsImg } from '../../assets'

const AboutUs = () => {
  return (
    <section
      id="about-us"
      data-w-id="b5ca0792-2e0b-503a-5ed0-d843ef4b3fdd"
      style={{
        WebkitTransform:
          "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        MozTransform:
          "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        msTransform:
          "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        transform:
          "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        opacity: 0
      }}
      className="section-aboutus"
    >
      <h4 className="heading-sub">About Us</h4>
      <h1 className="heading-main">
        <strong>
          Versatile Development Team: Adapting Expertise to Every Challenge
        </strong>
      </h1>
      <div className="w-layout-grid grid-aboutus">
        <div
          id="w-node-b5ca0792-2e0b-503a-5ed0-d843ef4b3fe4-503f3fac"
          className="div-aboutusleft"
        >
          <p style={{ display: "none" }} className="paragraph-aboutusless">
            We are a dynamic web development company specializing in both
            front-end and back-end solutions that bring your digital vision to
            life.
          </p>
          <p style={{ display: "block" }} className="paragraph-aboutusfull">
            We are a dynamic web development company specializing in both
            front-end and back-end solutions that bring your digital vision to
            life. Our team of skilled developers is dedicated to creating
            user-friendly, responsive interfaces while ensuring powerful,
            secure, and scalable back-end systems. Whether you need a sleek
            website, a complex web application, or seamless integration between
            the two, we deliver innovative solutions tailored to your specific
            needs. We combine cutting-edge technology with a client-focused
            approach to help you achieve your business goals in the digital
            space.
          </p>
          <a
            data-w-id="b5ca0792-2e0b-503a-5ed0-d843ef4b3ff9"
            style={{
              display: "none",
              WebkitTransform:
                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              MozTransform:
                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              msTransform:
                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              transform:
                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              backgroundColor: "rgba(NaN,NaN,NaN,undefined)",
              borderColor: "rgba(NaN,NaN,NaN,undefined)",
              color: "rgba(NaN,NaN,NaN,undefined)"
            }}
            href="#"
            className="button-readmoreaboutus w-button"
          >
            Read More
          </a>
          <a
            data-w-id="b5ca0792-2e0b-503a-5ed0-d843ef4b3ffb"
            style={{
              display: "inline-block",
              WebkitTransform:
                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              MozTransform:
                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              msTransform:
                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              transform:
                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              backgroundColor: "rgba(NaN,NaN,NaN,undefined)",
              borderColor: "rgba(NaN,NaN,NaN,undefined)",
              color: "rgba(NaN,NaN,NaN,undefined)"
            }}
            href="#"
            className="button-readlessaboutus w-button"
          >
            Read Less
          </a>
        </div>
        <img
          src={AboutUsImg}
          loading="lazy"
          sizes="(max-width: 479px) 88vw, (max-width: 767px) 75vw, (max-width: 991px) 71vw, 594px"
          srcSet="images/AboutUsHands-p-500.png 500w, images/AboutUsHands.png 594w"
          alt=""
          className="image-aboutus"
        />
      </div>
    </section>
  )
}

export default AboutUs
