import React from 'react'
import { IconLowCostImg, IconQuickLoadImg, IconRespnsiveImg, IconThreeUserImg } from '../../assets'

const Features = () => {
  return (
    <section
      id="features"
      data-w-id="12e9c06b-96b0-a8e6-ae81-8ac0e33545ae"
      style={{
        WebkitTransform:
          "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        MozTransform:
          "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        msTransform:
          "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        transform:
          "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        opacity: 0
      }}
      className="section-features"
    >
      <h4 className="heading-sub">Features</h4>
      <h1 className="heading-main">Our Web App Features</h1>
      <div className="w-layout-grid grid-features">
        <div
          id="w-node-_2bdcd615-e1a1-f0cd-f847-353303681bf3-503f3fac"
          data-w-id="2bdcd615-e1a1-f0cd-f847-353303681bf3"
          style={{
            WebkitTransform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            MozTransform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            msTransform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            transform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            opacity: 0
          }}
          className="div-feature"
        >
          <div className="div-featureimage">
            <img
              src={IconThreeUserImg}
              loading="lazy"
              width={64}
              id="w-node-b8fde297-170f-f77f-d98a-5061011115ea-503f3fac"
              alt=""
              className="image-feature"
            />
          </div>
          <h3
            id="w-node-cb6dd5f0-c619-3f34-668b-d40308422fd5-503f3fac"
            className="heading-feature"
          >
            User Friendly
          </h3>
          <p className="paragraph-feature">
            Experience lightning-fast browsing with a clean, minimalist design
            that maximizes efficiency.
          </p>
        </div>
        <div
          id="w-node-_87409e03-cb73-26f7-64eb-8438d851a3ae-503f3fac"
          data-w-id="87409e03-cb73-26f7-64eb-8438d851a3ae"
          style={{
            WebkitTransform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            MozTransform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            msTransform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            transform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            opacity: 0
          }}
          className="div-feature"
        >
          <div className="div-featureimage">
            <img
              src={IconRespnsiveImg}
              loading="lazy"
              width={33}
              id="w-node-_87409e03-cb73-26f7-64eb-8438d851a3b0-503f3fac"
              alt=""
              className="image-feature"
            />
          </div>
          <h3
            id="w-node-_87409e03-cb73-26f7-64eb-8438d851a3b1-503f3fac"
            className="heading-feature"
          >
            Responsive
          </h3>
          <p className="paragraph-feature">
            Crafting seamless user experiences with adaptive, responsive design.
          </p>
        </div>
        <div
          id="w-node-bd577c26-e8f6-18ad-dd6b-49ed0df5b5dd-503f3fac"
          data-w-id="bd577c26-e8f6-18ad-dd6b-49ed0df5b5dd"
          style={{
            WebkitTransform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            MozTransform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            msTransform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            transform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            opacity: 0
          }}
          className="div-feature"
        >
          <div className="div-featureimage">
            <img
              src={IconQuickLoadImg}
              loading="lazy"
              width={30}
              id="w-node-bd577c26-e8f6-18ad-dd6b-49ed0df5b5df-503f3fac"
              alt=""
              className="image-feature"
            />
          </div>
          <h3
            id="w-node-bd577c26-e8f6-18ad-dd6b-49ed0df5b5e0-503f3fac"
            className="heading-feature"
          >
            Quick Load
          </h3>
          <p className="paragraph-feature">
            Simplifying for speed: A streamlined site that loads in a flash.
          </p>
        </div>
        <div
          id="w-node-_6107905c-bfc9-273c-f4cb-b7fd34910aac-503f3fac"
          data-w-id="6107905c-bfc9-273c-f4cb-b7fd34910aac"
          style={{
            WebkitTransform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            MozTransform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            msTransform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            transform:
              "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            opacity: 0
          }}
          className="div-feature"
        >
          <div className="div-featureimage">
            <img
              src={IconLowCostImg}
              loading="lazy"
              width={33}
              id="w-node-_6107905c-bfc9-273c-f4cb-b7fd34910aae-503f3fac"
              alt=""
              className="image-feature"
            />
          </div>
          <h3
            id="w-node-_6107905c-bfc9-273c-f4cb-b7fd34910aaf-503f3fac"
            className="heading-feature"
          >
            Low Cost
          </h3>
          <p className="paragraph-feature">
            High-quality websites without the high price tag.
          </p>
        </div>
      </div>
    </section>
  )
}

export default Features
