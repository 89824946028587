import { AboutUs, Features, Hero, Services, Subscribe, WhyUs } from "../../components";

const Home = () => {

  return (
    <>
      <Hero />
      <Features />
      <WhyUs />
      <Services />
      <AboutUs />
      <Subscribe />
    </>
  );
};

export default Home;
