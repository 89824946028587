import React from 'react'
import { Link } from 'react-router-dom'
import { LogoWithColorImg } from '../../assets'

const Footer = () => {
  return (
    <section
      id="FooterSection"
      data-w-id="dde08c2e-59ad-30dd-11c6-2c21f8c30e90"
      className="section-footermenu"
    >
      <div className="w-layout-grid grid-navbar">
        <div
          id="w-node-dde08c2e-59ad-30dd-11c6-2c21f8c30e92-f8c30e90"
          className="div-footerbrand"
        >
          <Link
            to="/"
            aria-current="page"
            className="brand-navbar w-nav-brand w--current"
          >
            <img
              src={LogoWithColorImg}
              loading="lazy"
              width={68}
              alt=""
              className="image"
            />
            <div className="text-logo white">LW4U</div>
          </Link>
          <p className="paragraph-navbranddescription">
            Let's build highly optimized light weight user friendly website
            together
          </p>
        </div>
        <div
          id="w-node-dde08c2e-59ad-30dd-11c6-2c21f8c30e99-f8c30e90"
          className="div-navnonbrand"
        >
          <h3 className="heading-2">About</h3>
          <Link
            to="/#about-us"
            data-w-id="dde08c2e-59ad-30dd-11c6-2c21f8c30e9c"
            className="link-nav"
          >
            About us
          </Link>
          <Link to="/#features" className="link-nav">
            Features
          </Link>
          <Link to="/#services" className="link-nav">
            Services
          </Link>
        </div>
        <div
          id="w-node-dde08c2e-59ad-30dd-11c6-2c21f8c30ea2-f8c30e90"
          className="div-navnonbrand"
        >
          <h3 className="heading-2">Support</h3>
          <Link to="contact-us" className="link-nav">
            Contact us
          </Link>
        </div>
        <div
          id="w-node-dde08c2e-59ad-30dd-11c6-2c21f8c30ea7-f8c30e90"
          className="div-navnonbrand"
        >
          <h3 className="heading-2">Partner</h3>
          <Link to="/#subscribe" className="link-nav">
            Subscribe
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Footer
