import React from "react";
import { Link } from "react-router-dom";
import { LogoWithColorImg } from "../../assets";

const Header = () => {
  return (
    <section id="NavbarSection" className="section-navbar">
      <div
        data-animation="default"
        data-collapse="medium"
        data-duration={400}
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        className="navbar w-nav"
      >
        <div className="container-navbar w-container">
          <Link to="/"
            className="brand w-nav-brand w--current">
              <img
              src={LogoWithColorImg}
              loading="lazy"
              width={68}
              alt=""
              className="image"
            />
            <div className="text-logo">LW4U</div>

            </Link>
          
          <nav role="navigation" className="nav-menu w-nav-menu">
            <Link
              to="/"
              aria-current="page"
              className="nav-link w-nav-link w--current"
            >
              Home
            </Link>
            <Link to="/#features" className="nav-link w-nav-link">
              Features
            </Link>
            <Link to="/#services" className="nav-link w-nav-link">
              Services
            </Link>
            <Link
              to="/contact-us"
              className="button nav-link contact-us w-button"
              data-w-id="cdd2eaac-caf2-ee24-80ee-8fc23716787f"
            >
              Contact Us
            </Link>
            
          </nav>
          <div className="menu-button w-nav-button">
            <div className="w-icon-nav-menu" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
