export {default as AboutUsP500Img} from './AboutUsHands-p-500.png'
export {default as AboutUsImg} from './AboutUsHands.png'
export {default as HeroP500Img} from './Design-1-Hero-Image-p-500.png'
export {default as HeroImg} from './Design-1-Hero-Image.png'
export {default as IconLowCostImg} from './Icon---Low-Cost.png'
export {default as IconQuickLoadImg} from './Icon---Quick-Load.png'
export {default as IconRespnsiveImg} from './Icon---Responsive.png'
export {default as IconThreeUserImg} from './Icon---Three-User.png'
export {default as CurveLineImg} from './Icon-Curve-Line.png'
export {default as LogoWithColorImg} from './LW4U-Icon-With-Color.png'
export {default as HybrisLogoP500Img} from './SAP-Commerce-Cloud---Logo-p-500.png'
export {default as HybrisLogoImg} from './SAP-Commerce-Cloud---Logo.png'
export {default as ReactLogoImg} from './React---Logo.png'
export {default as SpringBootLogoImg} from './Spring-Boot---Logo.png'
export {default as SubscribeImg} from './Subscribe-Image.png'
export {default as WhyusP500Img} from './WhyUsLady-p-500.png'
export {default as WhyUsImg} from './WhyUsLady.png'
export {default as LoaderImg} from './Loading_Image.gif'
