import React, { useState } from "react";
import {
  addDoc,
  collection,
  db,
  Timestamp,
  query,
  where,
  getDocs,
} from "../../firebase/config";
import { toast } from "react-toastify";
import { Loader } from "../../components";

const initialState = {
  fullName: "",
  email: "",
  contactMsg: "",
};

const ContactUs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [contact, setContact] = useState({
    ...initialState,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setContact({ ...contact, [name]: value });
  };

  const saveContactDetails = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let isRecordPresent = false;

    try {
      const contactRef = collection(db, "contactUsRequests");

      const q = query(contactRef, where("email", "==", contact.email));
      const contactQuerySnapshot = await getDocs(q);
      contactQuerySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        isRecordPresent = true;
      });

      if (isRecordPresent) {
        setIsLoading(false);
        toast.error("You have already requested our contact. Thank you!");
      } else {
        const docRef = await addDoc(contactRef, {
          fullName: contact.fullName,
          email: contact.email,
          contactMsg: contact.contactMsg,
          createdAt: Timestamp.now().toDate(),
        });
        setIsLoading(false);
        setIsSubmitted(true);
        toast.success("Your request submitted successfully!");
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error.message);
      toast.error("Oops! Something went wrong while submitting the form.");
    }

    setContact({ ...initialState });
  };

  return (
    <>
      {isLoading && <Loader />}
      <section id="contactus" className="section-contactus">
        <div className="w-layout-grid grid-contactus">
          <div
            id="w-node-aa967acd-1bf5-6065-197d-eab6fa17b83f-221b99bc"
            className="div-contactusleft"
          >
            <h1 className="heading-main leftalign">Enquiry Form</h1>
            {!isSubmitted && (
              <>
                <p className="paragraph-flex">
                  If you have any questions or need help, please fill out the
                  form below. We do our best to respond within 1 business day.
                </p>
                <div id="SubscribeUs" className="form-block w-form">
                  <form
                    id="wf-form-SubscribeForm"
                    name="wf-form-SubscribeForm"
                    data-name="SubscribeForm"
                    method="post"
                    className="form"
                    data-wf-page-id="66c99d93e1337c5e221b99bc"
                    data-wf-element-id="4aaa1ff6-577d-612e-70e0-819ddd5db59f"
                    action="#"
                    onSubmit={(e) => saveContactDetails(e)}
                  >
                    <input
                      className="text-long w-input"
                      autoFocus={true}
                      maxLength={256}
                      name="fullName"
                      data-name="fullName"
                      placeholder="Full Name"
                      type="text"
                      id="fullName"
                      required
                      value={contact.fullName}
                      onChange={(e) => handleInputChange(e)}
                    />
                    <input
                      className="text-long w-input"
                      maxLength={256}
                      name="email"
                      data-name="email"
                      placeholder="Email ID"
                      type="email"
                      id="email"
                      required
                      value={contact.email}
                      onChange={(e) => handleInputChange(e)}
                    />
                    <textarea
                      placeholder="Message"
                      maxLength={5000}
                      id="contactMsg"
                      name="contactMsg"
                      data-name="message"
                      className="textarea w-input"
                      required
                      value={contact.contactMsg}
                      onChange={(e) => handleInputChange(e)}
                    />
                    <input
                      type="submit"
                      data-wait="Please wait..."
                      id="subscribeBtn"
                      data-w-id="4aaa1ff6-577d-612e-70e0-819ddd5db5a1"
                      style={{
                        WebkitTransform:
                          "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        MozTransform:
                          "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        msTransform:
                          "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        transform:
                          "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        backgroundColor: "rgba(NaN,NaN,NaN,undefined)",
                        borderColor: "rgba(NaN,NaN,NaN,undefined)",
                        color: "rgba(NaN,NaN,NaN,undefined)",
                      }}
                      className="button-block w-button"
                      defaultValue="SUBMIT"
                      action="#"
                    />
                  </form>
                </div>
              </>
            )}
            {isSubmitted && (
              <div
                id="SubscribeUs"
                className="form-block .w-contact-form w-form"
              >
                <div className="w-contact-form-success">
                  <div>Thank you! Your submission has been received!</div>
                </div>
              </div>
            )}
          </div>

          <div
            id="w-node-e8f7005d-e8a0-bac2-ad04-6f6818f30736-221b99bc"
            className="div-contactusright"
          >
            <div className="div-infobox">
              <h3 className="heading-info">
                Info
                <br />‍
              </h3>
              <div className="text-info">www.lightweightforyou.com</div>
              <div className="text-info">Timing: 9:00 - 18:00 IST</div>
            </div>
            <div className="div-yellowblock" />
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
