import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { CurveLineImg, HeroImg } from "../../assets";

const Hero = () => {
  useEffect(() => {
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require("ix2").init();
    document.dispatchEvent(new Event("readystatechange"));
  });

  return (
    <section
      id="hero"
      data-w-id="b9480548-1b74-bf3c-c836-7f8ce3f8e556"
      style={{
        WebkitTransform:
          "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        MozTransform:
          "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        msTransform:
          "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        transform:
          "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        opacity: 0,
      }}
      className="section-hero"
    >
      <div className="w-layout-grid grid">
        <div id="w-node-_369269c5-179f-472e-a920-e6d48c312d6a-503f3fac">
          <h1 className="heading">
            Simple,
            <br />
            Fast,User-Friendly,
            <br />
            Efficient.
          </h1>
          <img
            src={CurveLineImg}
            loading="lazy"
            alt=""
            className="image-line"
          />
          <p className="paragraph">
            Experience lightning-fast browsing with a clean, minimalist design
            that maximizes efficiency.
          </p>
          <Link
            data-w-id="4398b4f5-ce4c-d90b-b066-a2f0565e2c41"
            style={{
              WebkitTransform:
                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              MozTransform:
                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              msTransform:
                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              transform:
                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              backgroundColor: "rgba(NaN,NaN,NaN,undefined)",
              borderColor: "rgba(NaN,NaN,NaN,undefined)",
              color: "rgba(NaN,NaN,NaN,undefined)",
            }}
            to="/contact-us"
            className="button-normal w-button"
          >
            Contact Us
          </Link>
        </div>
        <div id="w-node-_634b686a-2895-7415-ab4b-b76f85faa23f-503f3fac">
          <img
            src={HeroImg}
            loading="lazy"
            width="Auto"
            sizes="(max-width: 767px) 100vw, (max-width: 991px) 889.742431640625px, 90vw"
            alt=""
            srcSet="images/Design-1-Hero-Image-p-500.png 500w, images/Design-1-Hero-Image.png 601w"
            className="image-hero"
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
