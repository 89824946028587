// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader_wrapper__JYeTW {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9;
}

.Loader_loader__pvYRd {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}`, "",{"version":3,"sources":["webpack://./src/components/loader/Loader.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,YAAA;EACA,aAAA;EACA,oCAAA;EACA,UAAA;AACJ;;AAEE;EACE,eAAA;EACA,SAAA;EACA,QAAA;EACA,gCAAA;EACA,YAAA;AACJ","sourcesContent":[".wrapper {\n    position: fixed;\n    width: 100vw;\n    height: 100vh;\n    background-color: rgba(0, 0, 0, 0.7);\n    z-index: 9;\n  }\n  \n  .loader {\n    position: fixed;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n    z-index: 999;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Loader_wrapper__JYeTW`,
	"loader": `Loader_loader__pvYRd`
};
export default ___CSS_LOADER_EXPORT___;
