import React from 'react'
import { WhyUsImg } from '../../assets'

const WhyUs = () => {
  return (
    <section
      id="whyus"
      data-w-id="259bc67b-20e7-b8bf-2e68-b8eec4437456"
      style={{
        WebkitTransform:
          "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        MozTransform:
          "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        msTransform:
          "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        transform:
          "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        opacity: 0
      }}
      className="section-whyus"
    >
      <h4 className="heading-sub">Why Us?</h4>
      <h1 className="heading-main">Performance &amp; Responsive WebApps</h1>
      <div
        data-w-id="f3512d8b-72a9-6eea-011e-cfb9fab0c902"
        className="w-layout-grid grid-whyus"
      >
        <img
          src={WhyUsImg}
          loading="lazy"
          sizes="(max-width: 479px) 100vw, (max-width: 767px) 75vw, (max-width: 991px) 71vw, 579px"
          srcSet="images/WhyUsLady-p-500.png 500w, images/WhyUsLady.png 579w"
          alt=""
          className="image-whyus"
        />
        <div
          id="w-node-_108bb449-0231-3e29-eee8-7d02552b5240-503f3fac"
          className="div-whyusright"
        >
          <p style={{ display: "block" }} className="paragraph-whyusless">
            At our website development company, we specialize in creating
            lightweight, sleek websites that are not only visually stunning but
            also incredibly fast and efficient.
          </p>
          <p style={{ display: "none" }} className="paragraph-whyusfull">
            At our website development company, we specialize in creating
            lightweight, sleek websites that are not only visually stunning but
            also incredibly fast and efficient. We understand the importance of{" "}
            <strong>
              <em>performance</em>
            </strong>{" "}
            in today's digital landscape, which is why we focus on streamlined
            designs and{" "}
            <strong>
              <em>optimized</em>
            </strong>{" "}
            code to ensure quick load times and a seamless user experience. Our
            approach prioritizes{" "}
            <strong>
              <em>simplicity</em>
            </strong>{" "}
            and elegance, delivering sites that are easy to navigate and
            maintain while keeping costs low without compromising on quality.
            Let us help you build a modern,{" "}
            <strong>
              <em>responsive</em>
            </strong>{" "}
            website that stands out for its speed and sophistication.
          </p>
          <a
            data-w-id="9cc9b906-ea57-0387-fbd7-e2811012406e"
            style={{
              display: "inline-block",
              WebkitTransform:
                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              MozTransform:
                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              msTransform:
                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              transform:
                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              backgroundColor: "rgba(NaN,NaN,NaN,undefined)",
              borderColor: "rgba(NaN,NaN,NaN,undefined)",
              color: "rgba(NaN,NaN,NaN,undefined)"
            }}
            href="#"
            className="button-readmore w-button"
          >
            Read More
          </a>
          <a
            data-w-id="72bc5a62-9a72-eba8-059e-8f21a93aee78"
            style={{
              display: "none",
              WebkitTransform:
                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              MozTransform:
                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              msTransform:
                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              transform:
                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              backgroundColor: "rgba(NaN,NaN,NaN,undefined)",
              borderColor: "rgba(NaN,NaN,NaN,undefined)",
              color: "rgba(NaN,NaN,NaN,undefined)"
            }}
            href="#"
            className="button-readless w-button"
          >
            Read Less
          </a>
        </div>
      </div>
    </section>
  )
}

export default WhyUs
