import React, { useState } from "react";
import { SubscribeImg } from "../../assets";
import {
  addDoc,
  collection,
  db,
  Timestamp,
  query,
  where,
  getDocs,
} from "../../firebase/config";
import { toast } from "react-toastify";
import { Loader } from "../../components";

const Subscribe = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const handleInputChange = (e) => {
    const { value } = e.target;
    console.log(value);
    setEmail(value);
  };

  const subscribeUser = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let isRecordPresent = false;
    try {
      const subscribersRef = collection(db, "subscribers");

      const q = query(subscribersRef, where("email", "==", email));
      const subscriberQuerySnapshot = await getDocs(q);

      subscriberQuerySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        isRecordPresent = true;
      });
      if (isRecordPresent) {
        setIsLoading(false);
        toast.error("You are already subscribed. Thank you!");
      } else {
        const docRef = await addDoc(subscribersRef, {
          email: email,
          createdAt: Timestamp.now().toDate(),
        });
        // console.log("Document written with ID: ", docRef.id);
        setIsLoading(false);
        toast.success("Thank you! Your submission has been received!");
        setIsSubscribed(true);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error.message);
      toast.error("Oops! Something went wrong while submitting the form.");
    }
    setEmail("");
  };

  return (
    <>
      {isLoading && <Loader />}

      <section
        id="subscribe"
        data-w-id="c282c621-be16-063a-b424-db2fc1b9a7cc"
        style={{
          WebkitTransform:
            "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
          MozTransform:
            "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
          msTransform:
            "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
          transform:
            "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
          opacity: 0,
        }}
        className="section-subscribe"
      >
        {!isSubscribed && (
          <>
            <h4 className="heading-sub">Subscribe</h4>
            <h1 className="heading-main">Subscribe to our Newsletter</h1>
            <div className="w-layout-grid grid-subscribe">
              <div
                id="w-node-b99babc7-aab4-c710-79c1-4cc911c997fa-503f3fac"
                className="div-subscribegridleft"
              >
                <p>
                  Subscribe to our newsletter to receive exclusive offers,
                  latest news and updates.
                </p>
                <div id="SubscribeUs" className="w-form">
                  <form
                    id="SubscribeForm"
                    name="SubscribeForm"
                    data-name="SubscribeForm"
                    method="post"
                    data-wf-page-id="66bd91790c8b53dc503f3fac"
                    data-wf-element-id="fe3f8a8f-a010-049f-b142-bd58425737e0"
                    action="#"
                    onSubmit={(e) => subscribeUser(e)}
                  >
                    <input
                      className="text-emailid w-input"
                      maxLength={256}
                      name="email"
                      data-name="Email"
                      placeholder="Email ID"
                      type="email"
                      id="email"
                      required=""
                      value={email}
                      onChange={(e) => handleInputChange(e)}
                    />
                    <input
                      type="submit"
                      data-wait="Please wait..."
                      id="subscribeBtn"
                      data-w-id="fe3f8a8f-a010-049f-b142-bd58425737e7"
                      style={{
                        WebkitTransform:
                          "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        MozTransform:
                          "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        msTransform:
                          "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        transform:
                          "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        backgroundColor: "rgba(NaN,NaN,NaN,undefined)",
                        borderColor: "rgba(NaN,NaN,NaN,undefined)",
                        color: "rgba(NaN,NaN,NaN,undefined)",
                      }}
                      className="button-normal subscribe w-button"
                      defaultValue="Subscribe"
                    />
                  </form>
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>
                      Oops! Something went wrong while submitting the form.
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="w-node-_36784e86-b9be-1df5-fded-d40aa4db4ac7-503f3fac"
                className="div-subscribegridright"
              >
                <img src={SubscribeImg} loading="lazy" alt="" />
              </div>
            </div>
          </>
        )}
        {isSubscribed && (
          <h1 className="heading-main">
            Thank you! Your submission has been received!
          </h1>
        )}
      </section>
    </>
  );
};

export default Subscribe;
